import { graphql } from "gatsby";
import React from "react";
import PropTypes from "prop-types";
import Layout from "components/Layout";
import ImageTiles from "../widgets/ImageTiles";

export default function CaseStudiesPage({
  data: { allMarkdownRemark: { nodes: caseStudies = [] } = {} } = {},
}) {
  const items = caseStudies.map(
    ({ frontmatter: { title, slug, previewImage } }) => ({
      label: title,
      image: previewImage,
      linkUrl: slug,
    })
  );

  return (
    <Layout title="Case Studies">
      {items.length ? (
        <ImageTiles sectionTitle="Case Studies" items={items} />
      ) : (
        <p>There are currently no any case studies.</p>
      )}
    </Layout>
  );
}

CaseStudiesPage.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query SavCaseStudiesQuery {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/case-studies/**" }
        frontmatter: {
          slug: {
            in: [
              "farmhouse"
              "hwhamptons"
              "midnight-sun"
              "mountains"
              "tymholladay"
              "osterville"
            ]
          }
        }
      }
    ) {
      nodes {
        id
        frontmatter {
          title
          uid
          slug
          previewImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
